import { graphql } from 'gatsby';
import { createGatsbyPageComponent } from '@shapeable/web';
export default createGatsbyPageComponent('Page');

export const QUERY = graphql`
  query PageQuery($id: ID!) {
    platform {
      page(id: $id) {
        id slug path 
        name 
        title 
        pretitle
        subtitle
        titleRich { text }
        subtitleRich { text }
        published
        content { text }
        options { text }
        openGraph { title description { plain } image { thumbnails { full { url } } } }
        intro { plain text }
        outro { text }
        orderNumber
        product {
          id name slug path
          benefits {
            id name title description { text }
          }
          industryGroups {
            id name slug
            openGraph {
              title description { plain }
              image { url type static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } }
            }
          }
        }
        layout {
          id name slug component 
        }
        videos {
          id name url
          thumbnail { id url static { childImageSharp { gatsbyImageData } } }
        }
        imageAssets {
          id name image { id url type static { childImageSharp { gatsbyImageData } } }
        }
        people {
          id name slug path
          organisation { id name }
          linkedin
          position
          photo { id url type static { childImageSharp { gatsbyImageData } } }
        }
        embeds {
          citations {
            slug url name authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
          }
          pages {
            id name slug path
            openGraph {
              title description { plain }
              image { url type static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } }
            }
          }
          people {
            id name slug path
            organisation { id name }
            position
            photo { id url }
          }
          imageAssets {
            id
            slug
            image {
              id url type width height static { childImageSharp { gatsbyImageData(placeholder: NONE) } } 
            }
          }
        }
        
        parent {
          __typename id name path slug title subtitle pretitle 
          content { text }
          children {
            __typename id name path slug title subtitle pretitle 
            openGraph { description { text } }
            intro { text }
            orderNumber
            banner {
              name slug alternateText
              thumbnail { 
                id url width height static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } 
              }
            }
          }
        }

        
        children {
          __typename id name path slug title subtitle pretitle 
          openGraph { description { text } }
          intro { text }
          orderNumber
          banner {
            name slug alternateText
            thumbnail { 
              id url width height static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } 
            }
          }
        }
        banners {
          id name slug title description { text } alternateText
          image { 
            id url type width height static { childImageSharp { gatsbyImageData(placeholder: NONE) } } 
          }
        }
        slices {
          id name slug 
          disabled
          subtitle
          subtitleRich { text }
          title
          titleRich { text }
          created updated
          outro { text }
          intro { text }
          products {
            id name slug path description { text }
            banner { image { id url width height type static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } } }
            links { id name slug label isDownload url page { path } type { id name }  }
            industryGroups {
              id name slug
              openGraph {
                title description { plain }
                image { url type static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } }
              }
            } 
            backgroundColor { value }
            iconImage { image { id type width height url } }
            color { value }
          }
          images {
            id name slug alternateText description { text }
            image { 
              id url type width height static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } 
            }
          }
          pages {
            id name path disabled
          }
          people {
            __typename
            id name slug email
            photo { url type static { childImageSharp { gatsbyImageData } } grayscale { id url static { childImageSharp { gatsbyImageData } } } }
            position
            bio { text html(links: { target: "_blank" }) }
            linkedin
            organisation { id name }
          }
          links {
            id name url label isDownload type { id name } page { path }
          }
          options { text }
          files {
            id
            name
            slug
            file {
              id
              url
            }
            thumbnail {
              url
							static {
              	childImageSharp {
                	gatsbyImageData
                }
              }
            }
          }
          videos { 
            url 
            openGraph {
              image { url type static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } }
            }
          }
          color { value }
          backgroundColor { value }
          backgroundImages {
            image {
              url
            }
          }
          blocks {
            id name slug 
            title 
            subtitle
            page { id path name }
            links { id name slug label isDownload url page { path } type { id name } file { file { url } } }
            pages { id name }
            content { text }
            options { text }
            image { id name image { url type static { childImageSharp { gatsbyImageData } } } }
          }
          content { text }
          image { id }
          layout { id name slug component }
          organisations {
            id
            name
            url
            organisationStatus { id name }
            logoInverted { type url }
            logo { type url }
            openGraph { image { id url } }
          }
        }
      }
    }
  }
`;
